import React, { useEffect, useState } from 'react';
import './assets/scss/styles.scss';
import { Container } from 'react-bootstrap';
import { getCurrentLanguage, translate } from '../../assets/js/functions';
import { getEspaciosMapsMarks} from '../../assets/js/api';
import proj4 from 'proj4';

import { useLocation } from 'react-router-dom';

export default function MapEspacios(props){
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [resultCount, setResultCount] = useState(0)

    var map = null;

    const typologies = {
        '1': `🔵 ${translate('Represión de género')}`,
        '2': `🟠 ${translate('Fosa')}`,
        '3': `🟡 ${translate('Centro de detención')}`,
        '4': `🟣 ${translate('Trabajos forzados')}`,
        '5': `🟢 ${translate('Exilio')}`,
        '6': `🔴 ${translate('Otros memoriales')}`,
    }

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://sitna.tracasa.es/api/";
        script.async = true;
        script.onload = () => scriptLoaded();

        document.body.appendChild(script);
    }, [])

    function scriptLoaded(){

        var SITNA = window.SITNA;

        proj4.defs('EPSG:25830', "+proj=utm +zone=30 +ellps=GRS80 +units=m +no_defs");
        proj4.defs('EPSG:4326', "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs");


        map = new SITNA.Map("mapa", {
            workLayers: [
                {
                    id: '1',
                    type: SITNA.Consts.layerType.VECTOR,
                    title: typologies['1'],
                },
                {
                    id: '2',
                    type: SITNA.Consts.layerType.VECTOR,
                    title: typologies['2'],

                },
                {
                    id: '3',
                    type: SITNA.Consts.layerType.VECTOR,
                    title: typologies['3'],
                },
                {
                    id: '4',
                    type: SITNA.Consts.layerType.VECTOR,
                    title: typologies['4'],
                },
                {
                    id: '5',
                    type: SITNA.Consts.layerType.VECTOR,
                    title: typologies['5'],
                },
                {
                    id: '6',
                    type: SITNA.Consts.layerType.VECTOR,
                    title: typologies['6'],
                },
            ],

        });

        getEspaciosMapsMarks(props.match.params.id).then((data) => {
            setLoading(false);
            const queryParams = new URLSearchParams(location.search);
            const urlId = queryParams.get('id');

            const arr = data.filter(function(value) {
                return value.location !== undefined && value.location !== null;
            });

            const grouped = {};

            arr.forEach((item) => {
                const typologies = JSON.parse(item.typology_data);
                typologies.forEach((typology) => {
                    if (grouped[typology] === undefined) {
                        grouped[typology] = [];
                    }
                    grouped[typology].push(item);
                });
            })

            map.loaded(function () {
                Object.keys(grouped).forEach((group) => {
                    if (grouped[group].length ) {
                        grouped[group].map(res => {
                            const content = '<div class="map-popup-content"><h6><a href="/'+getCurrentLanguage()+'/search/espacio/'+res.section_id+'">'+res.title+'</a></h6></div>'

                            const locUTM = proj4('EPSG:4326', 'EPSG:25830', res.location);

                            setResultCount(prev => prev + 1);
                            map.addMarker(locUTM, {
                                layer: group,
                                data: content,
                                cssClass: `marca${group}`,
                                showPopup: (String(res.section_id) === urlId )
                            })
                        })
                    }
                });

                map.zoomToMarkers({extentMargin: 0});
            })
        });
    }


    return (
        <Container>
            {loading
                ? <div className="loader1"><div></div><div></div></div>
                : <p class="result_count">{translate("Resultados").toUpperCase() + ' ' + resultCount}</p>
            }
            <div id="mapa"></div>
        </Container>
    )
}
