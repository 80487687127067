import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { domain, getInformant, getReelTermsByAudiovisual, isChildren } from '../../assets/js/api';
import parse from 'html-react-parser';
import { BsPlayCircle } from "react-icons/bs";
import './assets/scss/styles.scss';
import { getPosterframe, translate } from '../../assets/js/functions';
import classnames from 'classnames';
import VideoModalMulti from '../Modals/VideoModalMulti';
import VideoModal from '../Modals/VideoModal';
import { FaChevronDown, FaChevronUp, FaMapMarkerAlt, FaUser } from 'react-icons/fa';

export default function Interview(props) {

    const [informant, setInformant]                 = useState(props.info ? props.info[0] : null);
    const [refInformant, setRefInformant]           = useState([]);
    const [audiovisual, setAudiovisual]             = useState(null);
    const [summary, setSummary]                     = useState(false);
    const [modal, setModal]                         = useState(false);
    const [loadedThumbnail]                         = useState(false);
    const [reelTerms, setReelTerms]                 = useState([]);
    const idInformant   = props.data && props.data.informant ? JSON.parse(props.data.informant) : null;

    useEffect(() => {
        if (!!idInformant.length){
            const promise = idInformant.map(
                informant => getInformant(informant).then(res => {return res.data.result})
            )
            Promise.all(promise).then(
                res => { setInformant(res); }
            )
        }
        setAudiovisual(props.data.audiovisual[0]);
        const idAudiovisuals = props.data.audiovisual.map(value => value.section_id);

        getReelTermsByAudiovisual(idAudiovisuals.join(',')).then(res => {
            setReelTerms(res.data.result)
            const promise = res.data.result &&  res.data.result.map(
                term => {
                    if (term.term_id.includes("on1_")){
                        return isChildren("on1_1879", term.term_id).then(
                            isChild => {
                                if (isChild.data.result[0]){
                                    if (isChild.data.result[0].term_id === term.term_id){
                                        return term.term
                                    }
                                }
                            }
                        )
                    }
                }
            )
            Promise.all(promise).then(
                terms => {
                    terms.map(
                        term => term && setRefInformant(refInformant => [...refInformant, term])
                    )
                }
            )
        })
    }, [])


    return dataReturn();


    function dataReturn(){
        return (
            informant || props.info ?
                <>
                <Col className="card">
                <Row>
                    <Col>
                    {
                        informant && informant.map(
                            (res, i) => (res[0]?.name && res[0]?.surname)
                              ? <h3 className='informant-name' key={i}>
                                    {res[0].name+" "+res[0].surname}
                                </h3>
                              : null
                        )
                    }
                    </Col>
                </Row>
                    <Row className="interview-item">
                        <Col md={3} className='mb-1'>
                            {audiovisual || props.data ?
                                    <div className={"video-thumbnail"+( loadedThumbnail ? "" : " loading-bg" )} onClick={() => setModal(!modal)}>
                                        {
                                            (audiovisual ? audiovisual.video : props.data.video_url) &&
                                            <img src={getPosterframe(domain+(audiovisual ? audiovisual.video : props.data.video_url))} alt={audiovisual ? audiovisual.video : props.data.video_url}/>
                                        }
                                        <BsPlayCircle/>
                                    </div>
                            : <div className="loader1"><div></div><div></div></div>
                            }
                        </Col>
                        <Col md={9}>
                            <Row>
                                <Col md={4}>
                                    { props.data && props.data.terms && !!props.data.terms.length &&
                                        props.data.terms.map(
                                            (term, i) => {
                                                if (term.term_id.includes("peri1_")){
                                                    return  <span key={i} className="period">
                                                                <span className='title'>{ translate("Período")+": " }</span>
                                                                <span className="text"> { term.term }</span>
                                                            </span>
                                                }
                                                return null
                                            }
                                        )
                                    }
                                    <div className='ref-data'>
                                            { informant[0][0]?.location &&
                                                <div className='dataProject'>
                                                <span className='title'>{translate("Población")}</span>
                                                    <div className='content'>
                                                        <span><FaMapMarkerAlt/> { informant[0][0].location.split(",")[0] }</span>
                                                    </div>
                                                </div>
                                            }
                                            { !!refInformant.length &&
                                                <div className='dataProject'>
                                                <span className='title'>{translate("Víctimas aludidas")}</span>
                                                    <div className='content'>
                                                        {refInformant.map(
                                                            (ref, i) => <span key={i}><FaUser/> { ref }</span>
                                                        )}
                                                    </div>
                                                </div>
                                            }
                                    </div>
                                </Col>

                                        <Col md={8} className='reel-container'>
                                                <div className='dataProject'>
                                                <span className='title'>{translate("Términos relacionados")}</span>
                                                    <div className='content'>
                                                        {!!reelTerms.length &&
                                                            reelTerms.map(
                                                                (res, i) =>
                                                                    <span key={i} className="reel-item">
                                                                        {res.term}
                                                                    </span>
                                                            )}
                                                    </div>
                                                </div>
                                        </Col>
                            </Row>
                        </Col>
                        <Col>
                            <div className="buttons">
                                <button onClick={() => setSummary(!summary)} className={classnames("btn btn-interview", {active : summary, isProject: true})}>{ translate("Ver resumen") } {summary ? <FaChevronUp/> : <FaChevronDown/> }</button>
                            </div>
                            {
                                summary &&
                                <Col md={12} className={classnames("summary-box", {show : summary})}>
                                    <span className="summary">{ props.data && props.data.abstract ? parse(props.data.abstract) : props.info && props.info.abstract ? parse(props.info.abstract) : "Resumen no disponible" }</span>
                                </Col>
                            }
                        </Col>
                    </Row>
                </Col>
                { (modal && audiovisual)?
                <>
                {props.data.audiovisual.length > 1?
                    <VideoModalMulti modal={modal} audiovisual={props.data.audiovisual} onClose={(val) => setModal(val)}/>
                :
                    <VideoModal modal={modal} audiovisual={audiovisual && audiovisual.video} subtitles={audiovisual.subtitles} onClose={(val) => setModal(val)}/>
                }
                </>
                :''}
                </>
            : <div className="loader1"><div></div><div></div></div>
        )
    }

}