import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Col, Container, Row } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { translate, locationsName } from '../../assets/js/functions';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { getSearchAdvMemorySpaces } from '../../assets/js/api';



export default function SearchAdvMemorySpaces(props) {
    const [loading, setLoading] = useState(false);
    const title = translate("Búsqueda avanzada");
    const [memorySpacesList, setMemorySpacesList] = useState(null);
    const [fondo, setFondo] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [search, setSearch] = useState({
        name: '',
        location: '',
    });

    function handleSubmit(event) {
        setSearchQuery([translate(fondo), ...Object.values(search)].filter(n => n !== '').join(', '));
        setMemorySpacesList(null);
        setLoading(true);
        getSearchAdvMemorySpaces(fondo, search)
            .then(res => {
                setLoading(false);
                setMemorySpacesList(res.data.result);
            })

        event.preventDefault();
    }

    function handleFondoChange(event) {
        const {value} = event.target;
        setMemorySpacesList(null);
        setFondo(value);
    }

    function handleInputChange(event) {
        const { name, value } = event.target;
        setSearch(prev => ({...prev, [name]: value}))
    }

    return (
        <div className="content" id="searchadv">
            <Breadcrumbs key="sites" currentSite={title}/>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Container>
                <div>
                    <h3 className="title-filter">{translate('Filtrar por')}</h3>
                    <Link className="btn button btn-cmn filter1 btn-wide" to={'/search/adv/'}>
                        {translate('Víctimas')}
                    </Link>
                    <Link className="btn button btn-cmn filter2 btn-wide" to={'/search/adv/sites/'}>
                        {translate('Lugares de represión')}
                    </Link>
                    <Link className="active btn button btn-cmn filter2 btn-wide" to={'/search/adv/memoryspaces/'}>
                        {translate('Espacios de memoria')}
                    </Link>
                </div>
                <form className="searcheradv" onSubmit={handleSubmit}>
                    <ul className="dbfilter">
                        <li className="input">
                            <label>
                                <input value="" checked={fondo === ''} type="radio" name="font" onChange={handleFondoChange} />
                                {translate('Todos')}
                            </label>
                        </li>
                        <li className="input">
                            <label>
                                <input value="Fosa" checked={fondo === 'Fosa'} type="radio" name="font" onChange={handleFondoChange} />
                                {translate('Fosa')}
                            </label>
                        </li>
                        <li className="input long">
                            <label>
                                <input value="Centro de detención" checked={fondo === 'Centro de detención'} type="radio" name="font" onChange={handleFondoChange} />
                                {translate('Centro de detención')}
                            </label>
                        </li>
                        <li className="input">
                            <label>
                                <input value="Trabajos forzados" checked={fondo === 'Trabajos forzados'} type="radio" name="font" onChange={handleFondoChange} />
                                {translate('Trabajos forzados')}
                            </label>
                        </li>
                        <li className="input">
                            <label>
                                <input value="Represión de género" checked={fondo === 'Represión de género'} type="radio" name="font" onChange={handleFondoChange} />
                                {translate('Represión de género')}
                            </label>
                        </li>
                        <li className="input">
                            <label>
                                <input value="Exilio" checked={fondo === 'Exilio'} type="radio" name="font" onChange={handleFondoChange} />
                                {translate('Exilio')}
                            </label>
                        </li>
                        <li className="input">
                            <label>
                                <input value="Otros memoriales" checked={fondo === 'Otros memoriales'} type="radio" name="font" onChange={handleFondoChange} />
                                {translate('Otros memoriales')}
                            </label>
                        </li>
                    </ul>
                    <Row>
                        <Col xl={6} lg={6} className="input">
                            <input type="text" name="name" onChange={handleInputChange} placeholder={translate('Nombre')} />
                        </Col>
                        <Col xl={6} lg={6} className="input">
                            <input type="text" name="location" onChange={handleInputChange} placeholder={translate('Población')} />
                        </Col>
                    </Row>
                    <button type="submit" className="button"><FaSearch/></button>
                </form>

                {
                    loading
                        ? <div className="loader1"><div></div><div></div></div>
                        : ''
                }
                {
                    (memorySpacesList !== null)
                        ? <div className="result-list ">
                            <h3>{searchQuery}</h3>
                            <h4>{memorySpacesList.length} { translate("resultados")} </h4>
                            <Row className="victims-list">
                                {memorySpacesList.map((object, i) => {
                                    return (
                                        <Col md={6} lg={4} key={i}>
                                            <Link to={'/search/espacio/'+object.section_id}>
                                                {object.title}
                                                {(object.municipality)
                                                    ? <> | <strong>{locationsName(object.municipality)}</strong></>
                                                    : ''
                                                }
                                            </Link>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </div>
                        : ''
                }

            </Container>
        </div>
    )
}