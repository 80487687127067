import React, { useEffect, useState } from 'react'
import Searcher from '../../components/Searcher/Searcher'
import { Link } from 'react-router-dom'
import './assets/scss/styles.scss';
import { FaTimes, FaPlus } from 'react-icons/fa';
import Modal from 'react-modal';
import LearnMore from '../../views/LearnMore/LearnMore';
import Helmet from 'react-helmet';
import { getCurrentLanguage, getLanguages, translate } from '../../assets/js/functions';
import Footer from '../../layouts/Footer/Footer';
import { Container } from 'react-bootstrap';

export default function Home({currentPage}){

    const [modal, setModal] = useState(false)

    useEffect(() => {
        currentPage && currentPage(window.location.pathname);
    }, [])

    return (
        <>
            <div id="menu-languages" style={{paddingRight: "45px"}}>
                { getLanguages().map(
                    lang =>
                        <a href={'/'+lang} key={lang} className={"language"+(lang === getCurrentLanguage() ? " active" : "" )}>{lang}</a>
                ) }
            </div>
            <div id="home">
                <Helmet>
                    <title>{translate("Buscador memoria - Navarra")}</title>
                </Helmet>
                <div className='btn-moreinfo'>
                    <Link to="#" className="btn-saber" onClick={() => setModal(!modal)}>{(getCurrentLanguage() != 'eu')?<FaPlus/>:''} {translate('Info')} {(getCurrentLanguage() == 'eu')?<FaPlus/>:''}</Link>
                </div>
                <div className="centered-home">
                    <small className="help-text">*{translate("Buscar víctimas, poblaciones, lugares de represión o espacios de memoria")}</small>
                    <Searcher placeholder={translate("")}/>
                    <p className="btn-advanzed">
                    <Link to="/search/adv">{ translate("Búsqueda avanzada") }</Link>
                    </p>
                </div>
                <div className='buttons-middle'>
                    <div className="buttons-home">
                        <Link to="/docs" className="btn button btn-cmn "><span>{ translate("Fondos documentales") }</span></Link>
                        <Link to="/interviews" className="btn button btn-cmn"><span>{ translate("Entrevistas") }</span></Link>
                        <Link to="/docs/search" className="btn button btn-cmn"><span>{ translate("Documentos por población") }</span></Link>
                    </div>
                    <Container>
                        <hr/>
                    </Container>
                    <div className="buttons-home">
                        <Link to="/maps" className="btn button btn-cmn"><span>{ translate("Mapas") }</span></Link>
                        <Link to="/expositions" className="btn button btn-cmn"><span>{ translate("Exposiciones") }</span></Link>
                    </div>
                    <Container>
                        <hr/>
                    </Container>
                    {/*<div className="buttons-home">
                        <Link to="/biographic" className="btn button btn-cmn btn-big"><span>{ translate("Recorridos biográficos (Víctimas)") }</span></Link>
                </div>*/}
                </div>
            </div>
            <div className="footer-home">
                <Footer />
            </div>
            <Modal isOpen={modal}
                contentLabel="learnMoreModal"
                ariaHideApp={false}
            >
                <h2>{translate("Saber más")}</h2>
                <span className="closeModal" onClick={() => setModal(!modal)}><FaTimes/></span>
                <LearnMore/>
            </Modal>
        </>
    )
}
