import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './assets/scss/styles.scss';
import { FaSearch } from "react-icons/fa";
import Helmet from 'react-helmet';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { translate, locationsName, victimNameDisplay } from '../../assets/js/functions';
import { getSearchSitesAdv, getSearchSitesAdvFosa, getSitesVictims } from '../../assets/js/api';

export default function SearchAdvSites(props){

    const [loading, setLoading] = useState(false);
    const title = translate("Búsqueda avanzada");
    const [sitesList, setSitesList] = useState(null);
    const [fondo, setFondo] = useState('fosa');
    const [searchText, setSearchText] = useState('');
    const [searchArrayText, setSearchArrayText] = useState({
        fondo: '',
        name: '',
        location: '',
    });
    const [search, setSearch] = useState({
        name: '',
        location: '',
    });
    const [selectedSite, setSelectedSite] = useState(null);

    useEffect(() => {
        if(props.match){
            const id = props.match.params.id;
            const fondo = props.match.params.group;
            setFondo(fondo);

            if (fondo === 'fosa') {
                setLoading(true);
                getSearchSitesAdvFosa(fondo, search).then(res => {
                    setLoading(false);
                    if (!(res.data.result)) {
                        setSitesList(null);
                        return;
                    }
                    const result = res.data.result;
                    setSitesList(result);
                });
            } else {
                setLoading(true);
                getSitesWidthVictims(fondo, search).then(res => {
                    setLoading(false);
                    setSitesList(res);
                    if (id) {
                        const elem = res.find(value => value.term_id === id);
                        if (elem) {
                            selectSiteHandler(elem);
                        }
                    }
                });
            }
        }
    }, []);

    function filterVictims(elem, victims) {
        const center = elem.term_id;
        return victims.filter(value => {
            if (!value.movements_capture_data || value.movements_capture_data.length === 0) {
                return false;
            }
            let result = false;
            value.movements_capture_data.forEach(capture => {
                value.movements_capture_data.forEach(capture => {
                    if (capture.detention_center_data && capture.detention_center_data.search('\"'+center+'\"') >= 0) {
                        result = true;
                    }
                    if (capture.origin_center_data && capture.origin_center_data.search('\"'+center+'\"') >= 0) {
                        result = true;
                    }
                    if (capture.destination_center_data && capture.destination_center_data.search('\"'+center+'\"') >= 0) {
                        result = true;
                    }
                });
            });
            return result;
        });
    }

    function getSitesWidthVictims(fondo, search) {
        return Promise.all([
            getSitesVictims(),
            getSearchSitesAdv(fondo, search)
        ])
        .then((res) => {
            const victims = res[0];
            const campos = res[1];
            if (campos === null) {
                return;
            }
            return campos.map(elem => {
                elem.victims = filterVictims(elem, victims);
                return elem;
            }).filter(elem => {
                return elem.victims.length > 0;
            });
        });
    }

    function handleSubmit(event) {
        setSearchText(Object.values(searchArrayText).filter(n => n !== '').join(', '));
        setSelectedSite(null);
        setSitesList(null);
        setLoading(true);
        if (fondo === 'fosa') {
            getSearchSitesAdvFosa(fondo, search).then(res => {
                setLoading(false);
                if (!(res.data.result)) {
                    setSitesList(null);
                    return;
                }

                const result = res.data.result;
                setSitesList(result);
            });
        } else {
            getSitesWidthVictims(fondo, search).then(res => {
                setLoading(false);
                setSitesList(res);
            });
        }
        event.preventDefault();
    }

    function handleFondoChange(event) {
        const target = event.target;
        let tmpText = searchArrayText;
        tmpText.fondo = target.getAttribute("data-text");
        setSitesList(null);
        setSelectedSite(null);
        setFondo(target.value);
        setSearchArrayText(tmpText);
    }

    function handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        let tmp = search;
        let tmpText = searchArrayText;

        if (target.tagName == 'SELECT') {
            const value = event.target.value;
            tmp[name] = value;
            if (value !== '') {
                tmpText[name] = target.options[target.selectedIndex].text;
            } else {
                tmpText[name] = '';
            }
        } else {
            const value = target.type === 'checkbox' ? target.checked : target.value;
            tmp[name] = value;
            tmpText[name] = value;
        }
        setSearch(tmp);
        setSearchArrayText(tmpText);
    }

    function backHandler() {
        setSelectedSite(null);
    }

    function selectSiteHandler(elem) {
        setSelectedSite(elem);
    }

    return (
        <div className="content" id="searchadv">
            <Breadcrumbs key="sites" currentSite={title}/>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Container>

            <div>
            <h3 className="title-filter">{translate('Filtrar por')}</h3>
            <Link className="btn button btn-cmn filter1" to={'/search/adv/'}>{translate('Víctimas')}</Link>
            <Link className="active btn button btn-cmn filter2" to={'/search/adv/sites/'}>{translate('Lugares de represión')}</Link>
            <Link className="btn button btn-cmn filter2" to={'/search/adv/memoryspaces/'}>{translate('Espacios de memoria')}</Link>
            </div>
            <form className="searcheradv" onSubmit={handleSubmit}>
                <ul className="dbfilter">
                    <li className="input">
                        <label>
                            <input value="fosa" checked={fondo === 'fosa'} data-text={translate('Fosa')} type="radio" defaultChecked={fondo==='fosa'} name="font" onChange={handleFondoChange} />
                            {translate('Fosa')}
                        </label>
                    </li>
                    <li className="input long">
                        <label>
                            <input value="prision" checked={fondo === 'prision'} data-text={translate('Prisión')} type="radio" defaultChecked={fondo==='prision'} name="font" onChange={handleFondoChange} />
                            {translate('Prisión')}
                        </label>
                    </li>
                    <li className="input">
                        <label>
                            <input value="campo" checked={fondo === 'campo'} data-text={translate('Campo de concentración')} type="radio" defaultChecked={fondo==='campo'} name="font" onChange={handleFondoChange} />
                            {translate('Campo de concentración')}
                        </label>
                    </li>
                    <li className="input">
                        <label>
                            <input value="batallon" checked={fondo === 'batallon'} data-text={translate('Batallón de trabajadores')} type="radio" defaultChecked={fondo==='batallon'} name="font" onChange={handleFondoChange} />
                            {translate('Batallón de trabajadores')}
                        </label>
                    </li>
                </ul>
                <Row>
                    <Col xl={6} lg={6} className="input">
                        <input type="text" name="name" onChange={handleInputChange} placeholder={translate('Nombre')} />
                    </Col>
                    <Col xl={6} lg={6} className="input">
                        <input type="text" name="location" onChange={handleInputChange} placeholder={translate('Población')} />
                    </Col>
                </Row>
                <button type="submit" className="button"><FaSearch/></button>
            </form>

            {loading?
                <div className="loader1"><div></div><div></div></div>
            :''}
            {(selectedSite !== null)?
            <div className="result-list">
                <Link className="back" to="" onClick={(event) => {event.preventDefault();backHandler()}}>{translate("Volver al listado de ")}{searchText}</Link>
                <h3>{translate("Listado")}</h3>
                <h4>
                    {selectedSite.term}
                    {(selectedSite.location_name)?<> | <strong>{locationsName(selectedSite.location_name)}</strong></>:''}
                </h4>
                <Row className="victims-list">
                {selectedSite.victims.map(function(object, i){
                    return <Col md={6} lg={4}><Link to={'/search/victim/'+JSON.parse(object.victim)[0]} key={i} >{victimNameDisplay(object.ref_victim_name, object.ref_victim_surname)}</Link></Col>
                })}
                </Row>
            </div>
            :
            <>
            {(sitesList !== null)?
            <div className="result-list ">
                <h3>{searchText}</h3>
                <h4>{sitesList.length} { translate("resultados")} </h4>
                <Row className="victims-list">
                {sitesList.map(function(object, i){
                    if (fondo === 'fosa') {
                        return <Col md={6} lg={4}>
                        <Link to={'/search/fosa/'+object.section_id} key={i} >
                        {object.title}
                        {(object.municipality)?<> | <strong>{locationsName(object.municipality)}</strong></>:''}

                        </Link></Col>
                    } else {
                        return <Col md={6} lg={4}>
                        <Link to={'/search/adv/sites/'+fondo+'/'+object.term_id} key={i} onClick={(event) => {event.preventDefault();selectSiteHandler(object)}} >
                        {object.term}
                        {(object.location_name)?<> | <strong>{locationsName(object.location_name)}</strong></>:''}
                        </Link>
                        </Col>
                    }
                })}
                </Row>
            </div>
            :''}
            </>
            }

            </Container>


        </div>
        );
}
