import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './assets/scss/styles.scss';
import MapTestimonios from './MapTestimonios';
import MapNazi from './MapNazi';
import MapVictimas from './MapVictimas';
import MapFosas from './MapFosas';
import MapEspacios from './MapEspacios.js';
import MapSitios from './MapSitios';
import Helmet from 'react-helmet';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { translate } from '../../assets/js/functions';

export default function MapsList(props){

    const titleBreadcrum    = translate("Mapas");
    const [id, setId]       = useState(null);
    const breadcrumbsStatic = [{name: translate("Mapas"), url: "/maps"}];

    const searchTypes = {
        'testimonios': translate('Testimonios'),
        'golpe': translate('Víctimas mortales'),
        'economica': translate('Represión económica'),
        'exilio': translate('Exilio'),
        'deportacion': translate('Deportación'),
        'nazi': translate('Deportación en campos nazis'),
        'fosas': translate('Fosas'),
        'carceles': translate('Prisiones'),
        'campos': translate('Campos de concentración'),
        'espacios': translate('Espacios de memoria'),
    }

    const groupsTypes = {
        'victims': translate('Mapas por víctimas'),
        'sites': translate('Mapas por lugares de represión'),
        'espacios': translate('Mapas por espacios de memoria'),
    }

    useEffect(() => {
        if(props.match){
            const id = props.match.params.id;
            setId(id);
        }
    }, [props]);

    if (typeof searchTypes[id] !== 'undefined' && id === 'testimonios') {
        const title = searchTypes[id];
        return (
            <div className="map">
                <Breadcrumbs key={id} staticData={breadcrumbsStatic} currentSite={title}/>
                <h2>{title}</h2>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                <MapTestimonios {...props} />
            </div>
        );
    } else if (typeof searchTypes[id] !== 'undefined' && id === 'nazi') {
        const title = searchTypes[id];
        return (
            <div className="map">
                <Breadcrumbs key={id} staticData={breadcrumbsStatic} currentSite={title}/>
                <h2>{title}</h2>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                <MapNazi {...props} />
            </div>
        );
    } else if (typeof searchTypes[id] !== 'undefined' && id === 'fosas') {
        const title = searchTypes[id];
        return (
            <div className="map">
                <Breadcrumbs key={id} staticData={breadcrumbsStatic} currentSite={title}/>
                <h2>{title}</h2>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                <MapFosas {...props} />
            </div>
        );
    } else if (typeof searchTypes[id] !== 'undefined' && id === 'espacios') {
        const title = searchTypes[id];
        return (
            <div className="map">
                <Breadcrumbs key={id} staticData={breadcrumbsStatic} currentSite={title}/>
                <h2>{title}</h2>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                <MapEspacios {...props} />
            </div>
        );
    } else if (typeof searchTypes[id] !== 'undefined' && (id === 'carceles' || id === 'campos')) {
        const title = searchTypes[id];
        return (
            <div className="map">
                <Breadcrumbs key={id} staticData={breadcrumbsStatic} currentSite={title}/>
                <h2>{title}</h2>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                <MapSitios {...props} />
            </div>
        );
    } else if (typeof searchTypes[id] !== 'undefined') {
        const title = searchTypes[id];
        return (
            <div className="map">
                <Breadcrumbs key={id} staticData={breadcrumbsStatic} currentSite={title}/>
                <h2>{title}</h2>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                <MapVictimas {...props} />
            </div>
        );
    } else {
        return (
            <div id="mapas">
                <Breadcrumbs key={id} currentSite={titleBreadcrum}/>
                <Helmet>
                    <title>{titleBreadcrum}</title>
                </Helmet>
                <h2>{groupsTypes['victims']}</h2>
                <Container>
                    <Row>
                        <Col xs={12} md={3} className="theme-item project-item">
                            <Link className='btn-cmn' to={"/maps/testimonios"}>{searchTypes['testimonios']}</Link>
                        </Col>
                        <Col xs={12} md={3} className="theme-item project-item">
                            <Link className='btn-cmn' to={"/maps/golpe"}>{searchTypes['golpe']}</Link>
                        </Col>
                        <Col xs={12} md={3} className="theme-item project-item">
                            <Link className='btn-cmn' to={"/maps/economica"}>{searchTypes['economica']}</Link>
                        </Col>
                        <Col xs={12} md={3} className="theme-item project-item">
                            <Link className='btn-cmn' to={"/maps/exilio"}>{searchTypes['exilio']}</Link>
                        </Col>
                        <Col xs={12} md={3} className="theme-item project-item">
                            <Link className='btn-cmn' to={"/maps/deportacion"}>{searchTypes['deportacion']}</Link>
                        </Col>
                        <Col xs={12} md={3} className="theme-item project-item">
                            <Link className='btn-cmn' to={"/maps/nazi"}>{searchTypes['nazi']}</Link>
                        </Col>
                    </Row>
                </Container>
                <h2>{groupsTypes['sites']}</h2>
                <Container>
                    <Row>
                        <Col xs={12} md={3} className="theme-item project-item">
                            <Link className='btn-cmn' to={"/maps/fosas"}>{searchTypes['fosas']}</Link>
                        </Col>
                        <Col xs={12} md={3} className="theme-item project-item">
                            <Link className='btn-cmn' to={"/maps/carceles"}>{searchTypes['carceles']}</Link>
                        </Col>
                        <Col xs={12} md={3} className="theme-item project-item">
                            <Link className='btn-cmn' to={"/maps/campos"}>{searchTypes['campos']}</Link>
                        </Col>
                    </Row>
                </Container>

                <h2>{groupsTypes['espacios']}</h2>
                <Container>
                    <Row>
                        <Col xs={12} md={3} className="theme-item project-item mx-auto">
                            <Link className='btn-cmn' to={"/maps/espacios"}>{searchTypes['espacios']}</Link>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
