import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useHistory, useLocation } from 'react-router-dom'
import './assets/scss/styles.scss';
import { FaSearch } from "react-icons/fa";
import Helmet from 'react-helmet';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { translate, victimNameDisplay } from '../../assets/js/functions';
import { getSearchVictimsAdv } from '../../assets/js/api';

export default function SearchAdv(props){
    const history = useHistory();
    const location = useLocation();

    const title = translate("Búsqueda avanzada");
    const [victimsList, setVictimsList] = useState(null);
    const [id, setId] = useState(null);
    const [fondo, setFondo] = useState('');
    const [searchText, setSearchText] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        genero: '',
        nacimiento: '',
        residencia: '',
        defuncion: '',
    })

    useEffect(() => {
        if(props.match){
            const id = props.match.params.id;
            setId(id);
        }

        const queryParams = new URLSearchParams(location.search);
        const initialFondo = queryParams.get('fondo') || '';
        const initialSearch = {
            name: queryParams.get('name') || '',
            surname: queryParams.get('surname') || '',
            genero: queryParams.get('genero') || '',
            nacimiento: queryParams.get('nacimiento') || '',
            residencia: queryParams.get('residencia') || '',
            defuncion: queryParams.get('defuncion') || '',
        };

        setFondo(initialFondo);
        setFormData(initialSearch);
        if(queryParams.get('fondo')) {
            fetchData(initialSearch, initialFondo);
        }
    }, [props.match, location.search]);

    function fetchData(formDataObj, fondoStr) {

        const fondoTranslated = {
            'golpe': translate('Víctimas mortales'),
            'economica': translate('Víctimas represión económica'),
            'exilio': translate('Exiliados/as'),
            'deportacion': translate('Deportados/as'),
            'presos': translate('Presos/as'),
            'todas': translate('Todas')
        }

        const currentFondo = fondoStr !== '' ? fondoStr : 'todas'


        setSearchText(Object.values({fondo: fondoTranslated[currentFondo], ...formDataObj}).filter(n => n !== '').join(', '));

        getSearchVictimsAdv(fondoStr, formDataObj).then(res => {
            if (res === null) {
                setVictimsList(null);
                return;
            }

            const result = res.map(value => {
                if (value.table === 'informant') {
                    value.ref_victim_name = value.name;
                    value.ref_victim_surname = value.surname;
                    value.victim = value.section_id;
                } else if (value.table === 'deportation_victims_census') {
                    value.ref_victim_name = value.ref_nom;
                    value.ref_victim_surname = value.ref_cognom;
                    value.victim = JSON.parse(value.deportat)[0];
                } else {
                    value.victim = JSON.parse(value.victim)[0];
                }
                return value;
            });
            setVictimsList(result);
        });
    }

    function handleSubmit(event) {
        event.preventDefault();
        const queryParams = new URLSearchParams({...formData, fondo: fondo}).toString();
        history.push(`?${queryParams}`);
        fetchData(formData, fondo);
    }

    function handleFondoChange(event) {
        setVictimsList(null);
        setFondo(event.target.value);
    }

    function handleInputChange(event) {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const handleSelectChange = (e) => {
        setFormData({
            ...formData,
            genero: e.target.value
        })
    }

    return (
        <div className="content" id="searchadv">
            <Breadcrumbs key={id} currentSite={title}/>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Container>

            <div>
            <h3 className="title-filter">{translate('Filtrar por')}</h3>
            <Link className="active btn button btn-cmn filter1" to={'/search/adv/'}>{translate('Víctimas')}</Link>
            <Link className="btn button btn-cmn filter2" to={'/search/adv/sites/'}>{translate('Lugares de represión')}</Link>
            <Link className="btn button btn-cmn filter2" to={'/search/adv/memoryspaces/'}>{translate('Espacios de memoria')}</Link>

            </div>
            <form className="searcheradv" onSubmit={handleSubmit}>
                <ul className="dbfilter">
                    <li className="input">
                        <label>
                            <input value="" type="radio" name="fondo" onChange={handleFondoChange} checked={fondo === ''} />
                            {translate('Todas')}
                        </label>
                    </li>
                    <li className="input">
                        <label>
                            <input value="golpe" type="radio" name="fondo" onChange={handleFondoChange} checked={fondo === 'golpe'} />
                            {translate('Víctimas mortales')}
                        </label>
                    </li>
                    <li className="input long">
                        <label>
                            <input value="economica" type="radio" name="fondo" onChange={handleFondoChange} checked={fondo === 'economica'} />
                            {translate('Víctimas represión económica')}
                        </label>
                    </li>
                    <li className="input">
                        <label>
                            <input value="exilio" type="radio" name="fondo" onChange={handleFondoChange} checked={fondo === 'exilio'} />
                            {translate('Exiliados/as')}
                        </label>
                    </li>
                    <li className="input">
                        <label>
                            <input value="deportacion" type="radio" name="fondo" onChange={handleFondoChange} checked={fondo === 'deportacion'} />
                            {translate('Deportados/as')}
                        </label>
                    </li>
                    <li className="input">
                        <label>
                            <input value="presos" type="radio" name="fondo" onChange={handleFondoChange} checked={fondo === 'presos'} />
                            {translate('Presos/as')}
                        </label>
                    </li>
                </ul>
                <Row>
                    <Col xl={4} lg={6} className="input">
                        <input type="text" name="name" onChange={handleInputChange} value={formData.name} placeholder={translate('Nombre')} />
                    </Col>
                    <Col xl={4} lg={6} className="input">
                        <input type="text" name="surname" onChange={handleInputChange} value={formData.surname} placeholder={translate('Apellidos')} />
                    </Col>
                    <Col xl={4} lg={6} className="input">
                    <select name="genero" onChange={handleSelectChange} value={formData.genero}>
                        <option value="">{translate('Género')}</option>
                        <option value="Mujer">{translate('Mujer')}</option>
                        <option value="Hombre">{translate('Hombre')}</option>
                    </select>
                    </Col>
                    <Col xl={4} lg={6} className="input">
                    <input type="text" name="nacimiento" onChange={handleInputChange} value={formData.nacimiento} placeholder={translate('Municipio de nacimiento')} />
                    </Col>
                    <Col xl={4} lg={6} className="input">
                    <input type="text" name="residencia" onChange={handleInputChange} value={formData.residencia} placeholder={translate('Municipio de residencia')} />
                    </Col>
                    <Col xl={4} lg={6} className="input">
                    <input type="text" name="defuncion" onChange={handleInputChange} value={formData.defuncion} placeholder={translate('Municipio de defunción')} />
                    </Col>
                </Row>
                <button type="submit" className="button"><FaSearch/></button>
            </form>

            {(victimsList !== null)?
            <div className="result-list">
                <h3>{searchText}</h3>
                <h4>{victimsList.length} { translate("resultados")} </h4>
                <Row className="victims-list">
                {victimsList.map(function(object, i){
                    return <Col md={6} lg={4} key={i}><Link to={'/search/victim/'+object.victim}>{victimNameDisplay(object.ref_victim_name, object.ref_victim_surname)}</Link></Col>
                })}
                </Row>
            </div>
            :''}

            </Container>


        </div>
        );
}
