import { useEffect, useState } from "react"
import { getMemorySpace, domain } from "../../assets/js/api"
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { getPosterframe, locationsName, translate } from "../../assets/js/functions";
import { Helmet } from "react-helmet";
import { Container, Col, Row } from "react-bootstrap";
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { ReactComponent as IcoFosa } from '../../assets/images/icons/ico-fosa.svg'
import { ReactComponent as IcoLink } from '../../assets/images/icons/ico-link.svg'
import { ReactComponent as IcoDocumento } from '../../assets/images/icons/ico-documento.svg'
import { ReactComponent as IcoDetencion } from '../../assets/images/icons/ico-centro-detencion.svg'
import { ReactComponent as IcoTrabajos } from '../../assets/images/icons/ico-trabajos-forzados.svg'
import { ReactComponent as IcoGenero } from '../../assets/images/icons/ico-represion-genero.svg'
import { ReactComponent as IcoExilio } from '../../assets/images/icons/ico-exilio.svg'
import { ReactComponent as IcoPdfAccesible } from '../../assets/images/icons/pdf-accesible.svg'
import { ReactComponent as IcoPdfAccesible2 } from '../../assets/images/icons/pdf-accesible 2.svg'
import { ReactComponent as IcoVideoSignado } from '../../assets/images/icons/video-signado.svg'
import './assets/scss/styles.scss';
import { BsPlayCircle } from "react-icons/bs";
import VideoModal from "../../components/Modals/VideoModal";

function Icon({type}) {
    const IconsObj = {
        'link': <IcoLink />,
        'documento': <IcoDocumento />,
        '1': <IcoGenero />,
        '2': <IcoFosa />,
        '3': <IcoDetencion />,
        '4': <IcoTrabajos />,
        '5': <IcoExilio />,
        'pdf_accesible': <IcoPdfAccesible />,
        'pdf_accesible2': <IcoPdfAccesible2 />,
        'video_signado': <IcoVideoSignado />,
    }

    return IconsObj[type] ? (
        <div className="icon">
            {IconsObj[type]}
        </div>
    ) : null;
}


function Entity({data}) {
    const [showMore, setShowMore] = useState(false);
    const url = JSON.parse(data.url_data)[0].iri;

    return (
        <>
            <div className="entity-title">
                <p>{data.name}</p>
                <Link
                    onClick={(event) => {
                        event.preventDefault();
                        setShowMore(prev=>!prev);
                    }}
                    className="btn-mas-small" to=""
                >
                    {showMore ? '-' : '+'}
                </Link>

            </div>
            {showMore && <>
                <p>{data.description}</p>
                <div className="link">
                    <Icon type="link" />
                    <a href={url} target="_blank" rel="noreferrer">{url}</a>
                </div>
            </>}
        </>
    )
}


export default function MemorySpace(props) {
    const [spaceId, setSpaceId] = useState(null);
    const [spaceData, setSpaceData] = useState(null);
    const [moreInfoDescription, setMoreInfoDescription] = useState(false);
    const [moreInfoHistory, setMoreInfoHistory] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({
        video: null,
        subtitles: null
    })
    const [documents, setDocuments] = useState([]);
    const [accessibleDocuments, setAccessibleDocuments] = useState([]);
    const [videos, setVideos] = useState([]);
    const [accessibleVideos, setAccessibleVideos] = useState([]);

    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const typologies = {
        '1': translate('Represión de género'),
        '2': translate('Fosa'),
        '3': translate('Centro de detención'),
        '4': translate('Trabajos forzados'),
        '5': translate('Exilio'),
        '6': translate('Otros memoriales'),
    }

    useEffect(() => {
        if (props.match) {
            const spaceId = props.match.params.id;

            if (spaceId) {
                setSpaceId(spaceId);
                getMemorySpace(spaceId).then(spaceInfo => {
                    setSpaceData(spaceInfo);
                    setDocuments(spaceInfo.archives_data.filter(el => el.description === 'Documentos relacionados'))
                    setAccessibleDocuments(spaceInfo.archives_data.filter(el => el.description === 'Pdf accesible en lectura fácil'))
                    if (spaceInfo.audiovisual_data.length) {
                        spaceInfo.audiovisual_data.forEach(vid => {
                            if (vid.rsc36 === 'Audiovisual accesible') {
                                setAccessibleVideos(prev => [...prev, vid])
                            } else {
                                setVideos(prev => [...prev, vid])
                            }
                        })
                    }

                    if (spaceInfo.location_description.slice(0, 400).length >= spaceInfo.location_description.length) {
                        setMoreInfoDescription(true);
                    }
                });
            }
        }
    }, [])

    return (spaceData ?
        <div className="content" id="espacio">
            <Breadcrumbs key={spaceId} currentSite={translate("Buscador")}/>
            <>
            <Helmet>
                <title>{spaceData.title}</title>
            </Helmet>
            <Container>
                <div className="espacio-card">
                    <Row>
                        <Col md={8}>
                            {/* Titulo */}
                            <h2>
                                {spaceData.title}
                                {spaceData.municipality && <span className="espacio-location"> | {locationsName(spaceData.municipality)}</span>}
                            </h2>

                            {/* Tematicas */}
                            {spaceData.typology_data ?
                                <div>
                                    <strong>{translate('Temáticas').toUpperCase()}</strong>
                                    {JSON.parse(spaceData.typology_data).map(value => {
                                        return <div key={value}>
                                            <Icon type={value} />
                                            {typologies[value]}
                                        </div>
                                    })}
                                    {/* {spaceData.typology.split(' | ').map(value => <div key={value}>
                                        <Icon type={value} />
                                        {value}
                                    </div>)} */}
                                </div>

                            :''}

                            <br />

                            {/* Acceso publico */}
                            {spaceData.public_access === 1 && <p><strong>{translate('Acceso público')}: </strong>{translate('Si')}</p>}
                            {spaceData.public_access === 2 && <p><strong>{translate('Acceso público')}: </strong>{translate('No')}</p>}

                            {/* Localizacion */}
                            {spaceData.place && <Link to={`/maps/espacios?id=${spaceData.section_id}`} className="loc-link">
                                <p className="loc"><strong>{translate('Localización')}:</strong> {spaceData.place}</p>
                            </Link>}
                        </Col>
                        <Col md={4}>
                            {/* Imagenes */}
                            {spaceData.identify_image || spaceData.images ?
                                <Slider {...settings}>
                                    {spaceData.identify_image ?
                                        <figure>
                                            <div className="image-container">
                                                <img src={domain+spaceData.identify_image} alt="" />
                                            </div>
                                        </figure>

                                    :''}
                                    {spaceData.images ?
                                        spaceData.images.split(' | ').map(value =>
                                            <figure key={value}>
                                                <div className="image-container">
                                                    <img src={domain+value} alt="" />
                                                </div>
                                            </figure>
                                        )
                                    :''}
                                </Slider>
                            :''}

                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}>

                            {/* Descripcion */}
                            {spaceData.location_description ?
                                <>
                                    <br />
                                    <h3>{translate('Descripción')}</h3>
                                    {moreInfoDescription
                                        ? <p>{parse(spaceData.location_description)}</p>
                                        : <>
                                            <p>{parse(spaceData.location_description.slice(0, 400))}</p>
                                            <Link
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    setMoreInfoDescription(true);
                                                }}
                                                className="btn-mas" to=""
                                            >
                                                + {translate("Saber más")}
                                            </Link>
                                        </>
                                    }
                                </>
                            :''}

                            {/* Historia */}
                            {spaceData.historical_context ?
                                <>
                                <br /><br />
                                <h3>{translate('Historia')}</h3>
                                {moreInfoHistory
                                    ? <p>{parse(spaceData.historical_context)}</p>
                                    : <>
                                        <p>{parse(spaceData.historical_context.slice(0, 400))}</p>
                                        <Link
                                            onClick={(event) => {
                                                event.preventDefault();
                                                setMoreInfoHistory(true);
                                            }}
                                            className="btn-mas" to=""
                                        >
                                            {translate("+ Saber más")}
                                        </Link>
                                    </>
                                }
                                </>
                            :''}

                            {/* Asociaciones relacionadas */}
                            {spaceData.entity_data && spaceData.entity_data.length > 0 ?
                                <>
                                <br /><br />
                                <h5>{translate('Asociaciones relacionadas')}</h5>
                                <div className="info-list">
                                    {spaceData.entity_data.map(value => <Entity key={value.section_id} data={value} />)}
                                </div>
                                </>
                            :''}

                            <br />

                            {/* Enlaces relacionados */}
                            {spaceData.related_link ?
                                <>
                                <br /><br />
                                <h5>{translate('Enlaces relacionados')}</h5>
                                <div className="info-list">
                                    {JSON.parse(spaceData.related_link).map(value =>
                                        <div key={value.iri} className="link">
                                            <Icon type="link" />
                                            <a href={value.iri} target="_blank" rel="noreferrer">{value.title}</a>

                                        </div>
                                    )}
                                </div>
                                </>
                            :''}

                            <br />

                            {/* Documentos relacionados */}
                            {documents ?
                                <>
                                <br />
                                <h5>{translate('Documentos relacionados')}</h5>
                                <div className="info-list">
                                    {documents.map(value =>
                                        <div key={value.section_id}>
                                            <Icon type="documento" />
                                            <a href={domain+value.document} target="_blank" rel="noreferrer">{value.title}</a>
                                        </div>
                                    )}
                                </div>
                                </>
                            :''}

                        </Col>

                        <Col md={4}>

                            {/* Videos */}
                            {videos.length ?
                                <Slider {...settings} draggable={false}>
                                    {videos.map(value =>
                                        <figure key={value.section_id}>
                                            <button className="image-container" onClick={() => {
                                                setModalData({
                                                    video: value.video,
                                                    subtitles: value.subtitles
                                                });
                                                setShowModal(!showModal);
                                            }}>
                                                <img src={getPosterframe(domain+value.video)} alt="" />
                                                <BsPlayCircle/>
                                            </button>
                                        </figure>
                                    )}
                                </Slider>
                            :''}


                            {/* Fosa y victimas relacionadas */}
                            {spaceData.graves_map_data && spaceData.graves_map_data.length ?
                                <>
                                    <h5>{translate('Fosas y víctimas relacionadas')}</h5>
                                    <div className="info-list">
                                        {spaceData.graves_map_data.map((grave) =>
                                            <div key={grave.section_id}>
                                                <Icon type="2" />
                                                <Link to={'/search/fosa/'+ grave.section_id}>{grave.title}</Link>
                                            </div>
                                        )}
                                    </div>
                                    <br /><br />
                                </>
                            :''}

                            {/* Personas homenajeadas */}
                            {spaceData.people_honoured_data && spaceData.people_honoured_data.length ?
                                <>
                                    <h5>{translate('Personas homenajeadas')}</h5>
                                    <div className="info-list">
                                        {spaceData.people_honoured_data[0].people_data.length
                                            ? spaceData.people_honoured_data[0].people_data.map((person) =>
                                                <div key={person.section_id}>
                                                    <Icon type="link" />
                                                    <Link to={'/search/victim/' + person.section_id}>
                                                        {`${person.surname}, ${person.name}`}
                                                    </Link>
                                                </div>
                                            )
                                            : ''
                                        }
                                        {spaceData.people_honoured_data[0].related_link ?
                                            <div>
                                                <Icon type="link" />
                                                <a href={JSON.parse(spaceData.people_honoured_data[0].related_link)[0].iri}>
                                                    {spaceData.people_honoured_data[0].title}
                                                </a>
                                            </div>
                                        : ''}
                                    </div>
                                </>
                            : ''}


                            {/* Documentación accesible */}

                            {accessibleDocuments.length || accessibleVideos.length
                                ? <h3>{translate('Documentación accesible')}</h3>
                                : ''
                            }


                            {accessibleVideos.length ?
                                <>
                                <br />
                                <Icon type="video_signado" />
                                <span>{translate('Videos signados')}</span>
                                <Slider {...settings} draggable={false}>
                                    {accessibleVideos.map(value =>
                                        <figure key={value.section_id}>
                                            <button className="image-container" onClick={() => {
                                                setModalData({
                                                    video: value.video,
                                                    subtitles: value.subtitles
                                                });
                                                setShowModal(!showModal);
                                            }}>
                                                <img src={getPosterframe(domain+value.video)} alt="" />
                                                <BsPlayCircle/>
                                            </button>
                                        </figure>
                                    )}
                                </Slider>
                                </>
                            :''}
                            {accessibleDocuments.length ?
                                <>
                                <br />
                                <Icon type="pdf_accesible" />
                                <Icon type="pdf_accesible2" />
                                <span>{translate('Pdf accesible en lectura fácil')}</span>
                                <br /><br />
                                <div className="info-list">
                                    {accessibleDocuments.map(value =>
                                        <div key={value.section_id}>
                                            <Icon type="documento" />
                                            <a href={domain+value.document} target="_blank" rel="noreferrer">{value.title}</a>
                                        </div>
                                    )}
                                </div>
                                </>
                            :''}
                        </Col>
                    </Row>
                </div>
            </Container>
            </>

            { showModal && <VideoModal modal={showModal} audiovisual={modalData.video} subtitles={modalData.subtitles} onClose={() => setShowModal(!showModal)} />}
        </div>
        : <div className="loader1"><div></div><div></div></div>
    )
}